<template>
  <div class="container">
    <content-title :nav="nav"></content-title>
    <el-form :inline="true" >
  <el-form-item label="代理商">
    <el-input v-model="query.name" placeholder="输入商品名称"></el-input>
  </el-form-item>
  <el-form-item label="类型">
    <el-select v-model="query.type" placeholder="选择商品类型" clearable>
      <el-option label="代理商" value="agent"></el-option>
      <el-option label="二级代理商" value="sub_agent"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item label="状态">
    <el-select v-model="query.status" placeholder="选择状态" clearable>
      <el-option label="待上架" :value="0"></el-option>
      <el-option label="上架中" :value="1"></el-option>
       <el-option label="已下架" :value="2"></el-option>
    </el-select>
  </el-form-item>
  <el-form-item>
     <el-button  type="primary" @click="getList(1)">搜索</el-button>
  </el-form-item>
   <el-form-item>
     <el-button  type="primary" @click="add">新增</el-button>
  </el-form-item>
</el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #type="{ row }">
        <div>{{ type[row.type] }}</div>
      </template>
      <template #status="{ row }">
    <el-tag type="info" v-if="row.status === 0">待上架</el-tag>
<el-tag type="success" v-if="row.status === 1">上架中</el-tag>
<el-tag type="warning" v-if="row.status === 2">已下架</el-tag>

      </template>
      <template #handle="{ row }">
        <el-button size="small" type="primary" @click="update(row)"
          >编辑</el-button
        >
        <el-button size="small" v-if="row.status != 1 " type="success" @click="updateStatus(row,1)"
          >上架</el-button
        >
        <el-button size="small" v-if="row.status === 1" type="warning" @click="updateStatus(row,2)"
          >下架</el-button
        >
       
      </template>
    </auto-table>
    <AddorEdit @success="getList" ref="AddorEdit"></AddorEdit>

  </div>
</template>

<script>
import AddorEdit from "./components/AddorEdit.vue";
export default {
  components: {
    AddorEdit,

  },
  data() {
    return {
      nav: { firstNav: "代理商管理", secondNav: "代理商列表" },
      DataList: [],
     query:{
       name:'',
      type:'',
     },
      type: {
        coach_ip: "教练ip",
        cloudShop: '云店'
      },
      Option: [
        { name: "名称", value: "name" },
        { name: "售价", value: "discount_amount" },
        { name: "原价", value: "amount" },
        { name: "类型", value: "type", type: "custom" },
        { name: "已售", value: "sale_num" },
          { name: "状态", value: "status", type: "custom" },
        { name: "创建时间", value: "create_time" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {
    updateStatus(row,status){
         this.$axios({
        url: "/user/product/update",
        method:'post',
        data:{
            id:row.id,
            status
        }
      }).then((res) => {
       this.getList(1)
      });
    },
    
    add() {
      this.$refs.AddorEdit.open();
    },
    update(row) {
      this.$refs.AddorEdit.open(row);
    },
    
    //记录
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/product/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          name:this.query.name || null,
          status:this.query.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>